// src/components/AnimatedArrowButton.js
import React, { useState, useEffect } from 'react';
import './AnimatedArrowButton.css';
import arrow from "./arrow.png"

const AnimatedArrowButton = () => {
  const [isButtonFixed, setButtonFixed] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the height of the first section (assuming 100vh) or any other threshold if needed
      const firstSectionHeight = window.innerHeight;

      // Update the fixed button state based on the scroll position
      setButtonFixed(window.scrollY < firstSectionHeight);
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToNextSection = () => {
    const nextSection = document.querySelector('#next-section');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <button className={`animated-arrow-button${isButtonFixed ? ' fixed' : ''}`} onClick={scrollToNextSection}>
      <img src={arrow} alt="Arrow" /> {/* Display the custom arrow PNG */}
    </button>
  );
};

export default AnimatedArrowButton;
