import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, Element } from "react-scroll";
import { Modal } from "react-bootstrap";
import Navbar from "./navbar.js";
import AnimatedArrowButton from "./Compnents/AnimatedArrowButton.js";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import frontendDevImage from "./assets/laptop.jpg";
import reactImage from "./assets/react.jpg";
import responsiveDesignImage from "./assets/responsive.jpg";
import uiuxDesignImage from "./assets/ui.jpg";
import mobileDevImage from "./assets/mobile.jpg";
import facebookIcon from "./assets/facebook-icon.png";
import twitterIcon from "./assets/twitter-icon.png";
import mailIcon from "./assets/mail.png";
import instagramIcon from "./assets/instagram-icon.png";
import condition from "./assets/condition.PNG";
import webdesign from "./assets/webdesign.png";
import fitnesslobby from "./assets/fitnesslobby.png";
import logoImage from "./assets/mathew1.png";
import fist from "./assets/fist.png"


const App = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [showClickablePopup, setShowClickablePopup] = useState(false);

  
  const handleShowPopup = (index, event) => {
    if (event.target.classList.contains("learn-more-button")) {
      setSelectedSkill(index);
      setShowPopup(true);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    setShowClickablePopup(true);
    
    const timeout = setTimeout(() => {
      setShowClickablePopup(false);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);


  const skills = [
    {
      title: "Web Design",
      image: webdesign,
      description:
        "I have expertise in creating visually appealing and user-friendly web designs.",
      detailedDescription:
        "Web design is my specialty. I have a keen eye for aesthetics and a deep understanding of user experience. When creating web designs, I focus on combining visual appeal with functionality. I strive to create websites that not only look stunning but also provide a seamless and intuitive user experience. From choosing the right color palettes and typography to crafting layouts that enhance usability, I pay attention to every detail to ensure the final product exceeds expectations. Whether it's a personal portfolio, a corporate website, or an e-commerce platform, I can design a website that aligns with your brand identity and engages your target audience.",
    },
    {
      title: "Frontend Development",
      image: frontendDevImage,
      description:
        "I specialize in frontend development using HTML, CSS, and JavaScript to create interactive user interfaces.",
      detailedDescription:
        "Frontend development is my area of expertise. I have a strong command of HTML, CSS, and JavaScript, which are the building blocks of the web. Using these technologies, I can bring your web designs to life and create interactive and dynamic user interfaces. I ensure that the frontend code I write is clean, well-structured, and optimized for performance. Whether it's implementing complex animations, integrating third-party APIs, or optimizing the website for search engines, I have the skills and experience to handle various frontend development tasks. With a focus on code quality and best practices, I deliver frontend solutions that are both visually appealing and highly functional.",
    },
    {
      title: "React",
      image: reactImage,
      description:
        "I have a strong command of React and use it to build scalable and efficient web applications.",
      detailedDescription:
        "React is my go-to framework for building web applications. With my strong command of React, I can create scalable and efficient solutions that meet your business needs. React's component-based architecture allows me to build reusable UI components, resulting in faster development and easier maintenance. Whether it's a single-page application or a complex web portal, I can leverage the power of React to deliver high-quality web applications. I also have experience with state management libraries like Redux and MobX, which enable me to manage application state effectively. With React, I can provide a modern and seamless user experience while ensuring optimal performance and maintainability.",
    },
    {
      title: "Responsive Design",
      image: responsiveDesignImage,
      description:
        "I ensure that my websites are responsive and provide a seamless experience across different devices and screen sizes.",
      detailedDescription:
        "In today's mobile-centric world, it's crucial to have a website that adapts seamlessly to different devices and screen sizes. That's where responsive design comes in. I prioritize responsive design in all my projects to ensure that your website looks and functions flawlessly on smartphones, tablets, laptops, and desktops. By using CSS media queries and flexible layouts, I create websites that automatically adjust their content and layout based on the user's device. This approach guarantees a consistent and enjoyable user experience across all platforms. Whether your visitors are accessing your website on a small smartphone screen or a large desktop monitor, they will have a seamless and visually appealing experience.",
    },
    {
      title: "UI/UX Design",
      image: uiuxDesignImage,
      description:
        "I have a deep understanding of user-centered design principles to create intuitive and engaging user interfaces.",
      detailedDescription:
        "User interface (UI) and user experience (UX) design are at the core of my design process. I believe in creating intuitive and engaging interfaces that prioritize the needs and expectations of the users. Through user research and analysis, I gain insights into the target audience, their goals, and pain points. This understanding guides me in designing interfaces that are both aesthetically pleasing and easy to navigate. I pay attention to factors such as information architecture, interaction design, and visual hierarchy to create interfaces that are visually appealing, functional, and accessible. By conducting usability testing and gathering user feedback, I continuously iterate and refine my designs to ensure the best possible user experience.",
    },
    {
      title: "Mobile Development",
      image: mobileDevImage,
      description:
        "I develop mobile applications using React Native, delivering cross-platform experiences for iOS and Android.",
      detailedDescription:
        "With the increasing popularity of mobile applications, having a presence on both iOS and Android platforms is essential. As a mobile developer, I specialize in using React Native to build cross-platform applications. React Native allows me to write code once and deploy it on both iOS and Android devices, saving time and resources. I leverage the power of React Native's component library to create native-like interfaces and ensure a seamless user experience. Whether it's a business app, social media platform, or e-commerce application, I can develop mobile solutions that meet your specific requirements. I follow industry best practices and stay up to date with the latest trends in mobile development to deliver high-quality, performant, and user-friendly mobile applications.",
    },
  ];
  const workDone = [
    {
      title: "Condition Digital",
      image: condition,
      description: "A global custom software development company that provides digital solutions focused on Application, Blockchain, Systems, Automation and Security.",
      website: "https://conditiondigital.com",
    },
    {
      title: "The Fitness Lobby",
      image: fitnesslobby,
      description: "A reliable and evidence-based fitness information subscription website that empower's individuals on their fitenss and wellness journey.",
      website: "https://thefitnesslobby.com",
    },

  ];
  


  return (
    <div>
      <Navbar />
      <Element name="Home" className="section">
      <section id="Home">
        <div  className="main">
        <div className="new-card">
          <div className="container">
            <div className="content">
              <center>
                <h1>Hi, I'm Mathew</h1>
              </center>
              <h2 className="web">I'm a Web </h2>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="container">
            <div className="col-lg-12">
              <div className="card-content">
                <p>
                  <center>
                    I'm a frontend developer with expertise in HTML, CSS,
                    JavaScript, React, and React Native. I specialize in
                    creating visually appealing and interactive user interfaces
                    for web and mobile applications. With a focus on responsive
                    design and a deep understanding of modern frontend
                    practices, I transform designs into clean and efficient
                    code. By staying up-to-date with the latest trends and
                    technologies, I continuously enhance my skills to deliver
                    exceptional user experiences. Let's collaborate and bring
                    your ideas to life with seamless and engaging frontend
                    solutions.
                  </center>
                </p>
              </div>
            </div>
          </div>
        </div>
        <AnimatedArrowButton />
        </div>
      </section>
      </Element>
      <Element name="Services" className="section" id="next-section">
      <section className="skills" id="Skills">
        <div className="container">
          <div className="row">
            {skills.map((skill, index) => (
              <div
                key={index}
                className={`card-skills ${
                  selectedSkill === index ? "expanded" : ""
                }`}
                onClick={(event) => handleShowPopup(index, event)}
              >
                <img className="card-image" src={skill.image} alt={skill.title} />
                <div className="card-skills-content">
                  <h3 className="card-title">{skill.title}</h3>
                  <p className="card-description">{skill.description}</p>
                  <div className="button-container">
                    <button className="learn-more-button">Learn More</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      </Element>
      <Element name="Work" className="section">
        <section className="work" id="Projects">
          <div className="container">
            <div className="row">
              {workDone.map((work, index) => (
                <div key={index} className="card-work">
                  <img
                    className="card-work-image"
                    src={work.image}
                    alt={work.title}
                  />
                  <div className="card-work-content">
                    <h3 className="card-work-title">{work.title}</h3>
                    <p className="card-work-description">{work.description}</p>
                    <div className="button-container">
                      <a href={work.website} target="_blank" rel="noopener noreferrer">
                        <button className="learn-more-button">Visit Site</button>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </Element>

      <footer className="footer" id="contact">
      <div className="card-start-project-container">
  <div className="card-start-project">
    <h3>Start A Project</h3>
    <p>Interested in working together? Click here to sign up for a consultation</p>
    <button className="contact-me">
    <img src={fist} alt="" className="fist"/> <a href="https://www.linkedin.com/in/mathew-van-wyk-957936282/" className="btn-contact">Lets Do This!</a>
    </button>
  </div>
</div>
  <div className="footer-content">
    <a href="#Home">
  <img src={logoImage} alt="Your Logo" className="footer-logo" href="#Home" />
  </a>

    <div className="social-icons">
      <a href="https://www.linkedin.com/in/mathew-van-wyk-957936282/">
        <img src={facebookIcon} alt="LinkedIn" />
      </a>
      <a href="https://twitter.com/Mathew_frontend">
        <img src={twitterIcon} alt="Twitter" />
      </a>
      {/* <a href="#">
        <img src={instagramIcon} alt="Instagram" />
      </a>
      <a href="#">
        <img src={mailIcon} alt="Email" />
      </a> */}
    </div>
    <div className="additional-links">
      <ul className="additional-links-list">
        <li>
          <a href="#">Privacy Policy</a>
        </li>
        <li>
          <a href="#">Terms</a>
        </li>
      </ul>
    </div>
  </div>

</footer>





      {/* Popup */}
      <Modal show={showPopup} onHide={handleClosePopup} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedSkill !== null && skills[selectedSkill].detailedDescription}
        </Modal.Body>
        <Modal.Footer>
          <button onClick={handleClosePopup}>Close</button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default App;
