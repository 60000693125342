import React, { useState } from "react";
import "./navbar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logoImage from "./assets/mathew.png";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleLogoHover = () => {
    setIsHovered(true);

    setTimeout(() => {
      setIsHovered(false);
    }, 3000);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top">
      <a className="navbar-brand" href="#Home" onMouseEnter={handleLogoHover}>
        <img
          className={`logo ${isHovered ? "colorize" : ""}`}
          src={logoImage}
          alt="Logo"
          style={{ width: "40px", height: "40px" }}
        />
      </a>
      <button
        className={`navbar-toggler ${isOpen ? "open" : ""}`}
        type="button"
        onClick={toggleNavbar}
      >
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
        <span className="icon-bar"></span>
      </button>
      <div
        className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
        id="navbarCollapse"
      >
        <ul className="navbar-nav ms-auto">
          <li className="nav-item">
            <a className="nav-link" href="#Skills">
              Skills
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#Projects">
              Projects
            </a>
          </li>
          <li className="nav-item contact">
            <a className="nav-link" href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
